import { useApi } from "@core/contexts/ApiContext";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiCheckCircle, HiX } from "react-icons/hi";
import { useParams } from "react-router-dom";

import PoweredBy from "../../core/components/PoweredBy";
import { useConfig } from "../../core/contexts/ConfigContext";

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex flex-col items-center">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-transparent rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

const UploadWidget = () => {
  const { uploadToken } = useParams();
  const { api } = useApi();
  const { t } = useTranslation();

  const { config } = useConfig();
  const logoSrc =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  const [file, setFile] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [successApi, setSuccessApi] = useState(null);
  const [errorApi, setErrorApi] = useState();
  const [isLoadingApi, setIsLoadingApi] = useState(false);

  // "idle" | "loading" | "success" | "error" | "hidden"
  const [status, setStatus] = useState("idle");
  const [progress, setProgress] = useState(0);

  const clearError = () => {
    setErrorApi(null);
  };

  const onClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setStatus("loading");
      setErrorApi(null);
      setSuccessApi(null);
    }
  };

  useEffect(() => {
    if (status === "loading" && file) {
      // Simulate a 1/2-second upload with progress
      const uploadSimulation = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(uploadSimulation);
            setStatus("success");

            return 100;
          }
          return prev + 20;
        });
      }, 100);
    }
  }, [status, file]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setStatus("loading");
      setErrorApi(null);
      setSuccessApi(null);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingApi(true);
    try {
      await api.postResume(file, { uploadToken });
      setSuccessApi(
        t("uploadWidget.successMessage", { companyName: config?.company_name }),
      );
      setStatus("hidden");
    } catch (error) {
      setErrorApi(t("uploadWidget.errorMessage"));
    } finally {
      setIsLoadingApi(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-gray-50 items-center py-8 px-4">
      <div className="flex flex-col items-center text-center max-w-md w-full mb-4">
        {logoSrc ? (
          <img
            src={logoSrc}
            alt="Company avatar"
            className={"h-20 object-contain mb-4"}
          />
        ) : (
          <div
            className={"text-desktopNeutralBlack font-semiboald text-3xl mb-4"}
          >
            <span>{config?.company_name}</span>
          </div>
        )}

        {successApi && (
          <HiCheckCircle className="text-desktopGreenDark h-16 w-16 mb-4" />
        )}

        <h2 className="text-desktopNeutralDark text-2xl mb-4">
          {successApi
            ? t("uploadWidget.allDone")
            : t("uploadWidget.uploadYourResume")}
        </h2>

        <p className="text-desktopNeutralBlack text-sm">
          {successApi
            ? t("uploadWidget.successMessage", {
                companyName: config?.company_name,
              })
            : t("uploadWidget.uploadPrompt", {
                companyName: config?.company_name,
              })}
        </p>
      </div>

      {errorApi && (
        <div
          className="flex my-4 items-center bg-destopRedLightest border border-desktopRedVeryLight text-red-700 px-4 py-3 rounded relative mt-4 max-w-md"
          role="alert"
        >
          <button
            onClick={clearError}
            className="flex items-center justify-center border border-desktopVeryDark text-desktopVeryDark rounded-full mr-2 p-1"
          >
            <HiX className="h-3 w-3" />
          </button>

          <span className="text-desktopVeryDark block sm:inline">
            {errorApi}
          </span>
        </div>
      )}

      {status !== "hidden" && (
        <div className="flex flex-col rounded shadow-md bg-white w-full max-w-md p-6 items-center text-center justify-center h-2/6 md:h-1/5">
          {status === "idle" && (
            <>
              <div
                onClick={onClick}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                className={`border-2 rounded p-4 cursor-pointer flex flex-col items-center transition-colors duration-300 ${
                  isDragging
                    ? "border-primary border-dotted bg-primary/10"
                    : "border-primary"
                }`}
                tabIndex={0}
                role="button"
                aria-label={t("uploadWidget.uploadButton")}
                type="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") onClick();
                }}
              >
                <span className="text-primary">
                  {t("uploadWidget.uploadButton")}
                </span>
              </div>
              <div className="text-sm text-gray-500 mt-4">
                {t("uploadWidget.fileFormats")}
              </div>
            </>
          )}
          <input
            id="fileInput"
            type="file"
            accept=".doc,.docx,.pdf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={handleFileChange}
            className="hidden"
          />

          {status === "loading" && (
            <div className="flex flex-col items-center text-center">
              <div className="text-gray-500 mb-2">
                {t("uploadWidget.uploading", { fileName: file?.name })}
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-desktopPrimaryDark h-2.5 rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
          {status === "success" && (
            <div className="flex flex-col items-center text-center">
              <div className="text-gray-500 font-semibold">
                <span className="text-desktopPrimaryDark font-bold">
                  {file?.name}
                </span>{" "}
                {t("uploadWidget.fileReady")}
              </div>
            </div>
          )}
        </div>
      )}

      {status !== "hidden" && (
        <>
          <button
            type="button"
            onClick={onSubmit}
            disabled={!file}
            className={clsx("mt-4 p-4 shadow-md rounded", {
              "bg-primary text-white hover:bg-primary/75 focus:outline-none":
                file && status !== "loading",
              "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
                !file || status === "loading",
            })}
          >
            {status === "loading"
              ? t("uploadWidget.statusUploading")
              : t("uploadWidget.submitResume")}
          </button>
          <button onClick={onClick} disabled={!file} className="mt-4">
            <span
              className={clsx({
                "text-primary hover:text-primary/75":
                  file && status !== "loading",
                "text-desktopNeutralMedium cursor-not-allowed":
                  !file || status === "loading",
              })}
            >
              {t("uploadWidget.uploadDifferentFile")}
            </span>
          </button>
        </>
      )}
      <PoweredBy />
      <LoadingOverlay isLoading={isLoadingApi} />
    </div>
  );
};

export default UploadWidget;
