import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const userLang = navigator.language || navigator.userLanguage;
// eslint-disable-next-line no-undef
const bundledLocales = __BUNDLED_LOCALES__;

if (!i18n.isInitialized) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "en",
      lng: userLang,
      supportedLngs: ["en", "en-US", "es", "es-MX", "pt", "fr", "fr-CA", "de"],
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ["navigator"],
        caches: [],
      },
      resources: bundledLocales,
      react: {
        useSuspense: false,
      },
    });
}

export default i18n;
