import { useApi } from "@core/contexts/ApiContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";
import { useParams } from "react-router-dom";

import PoweredBy from "../../core/components/PoweredBy";
import { useConfig } from "../../core/contexts/ConfigContext";

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex flex-col items-center">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-transparent rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

const AuthorizationSessionWidget = () => {
  const { id: authCode } = useParams();
  const { api } = useApi();
  const { t } = useTranslation();
  const [successApi, setSuccessApi] = useState(null);
  const [errorApi, setErrorApi] = useState();
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [authorizeDetails, setAuthorizeDetails] = useState([]);

  useEffect(() => {
    getAuthorizationDetails();

    async function getAuthorizationDetails() {
      setIsLoadingApi(true);
      try {
        const res = await api.getAuthorize(authCode);
        setAuthorizeDetails(res?.data);
      } catch (err) {
        setNotFound(true);
      } finally {
        setIsLoadingApi(false);
      }
    }
  }, [api, authCode]);

  const { config } = useConfig();
  const logoSrc =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  const onSubmit = async (e, approve) => {
    e.preventDefault();
    setIsLoadingApi(true);
    setErrorApi(false);
    try {
      await api.putAuthorize(authCode, { code: authCode, approve });
      setSuccessApi(true);
    } catch (error) {
      setErrorApi(true);
    } finally {
      setIsLoadingApi(false);
    }
  };

  const expirationMinutes = (authorizeDetails?.expiration_minutes || 900) / 60;

  return (
    <div className="flex flex-col w-full h-full bg-gray-50 items-center py-8 px-4">
      <div className="flex flex-col items-center text-center max-w-md w-full mb-4">
        {logoSrc ? (
          <img
            src={logoSrc}
            alt="Company avatar"
            className="h-20 object-contain mb-4"
          />
        ) : (
          <div className="h-20 text-desktopNeutralBlack font-semiboald text-3xl mb-4">
            <span>{config?.company_name}</span>
          </div>
        )}

        {successApi && (
          <HiCheckCircle className="text-desktopGreenDark h-16 w-16 mb-4" />
        )}

        <h2 className="text-desktopNeutralDark text-2xl mb-4">
          {successApi
            ? t("authorizationSession.thankYou")
            : t("authorizationSession.accessRequest")}
        </h2>

        {successApi && (
          <div className="text-md text-gray-500 mt-4 mb-4">
            {t("authorizationSession.closeWindow")}
          </div>
        )}

        {notFound && (
          <div className="text-md text-gray-500 mt-4 mb-4">
            {t("authorizationSession.invalidOrExpired")}
          </div>
        )}
      </div>

      {successApi || notFound || (
        <div className="flex flex-col rounded shadow-md bg-white w-full max-w-md p-6 items-center text-center justify-center md:h-1/5">
          <div className="text-md text-gray-500 mt-4 mb-4">
            {t("authorizationSession.wasThisYou")}
          </div>
          {authorizeDetails?.requestor?.map((text, i) => (
            <div className="text-sm" key={i}>
              {text}
            </div>
          ))}
          <div className="text-sm text-gray-500 mt-4 mb-4">
            {t("authorizationSession.approveRequest")}
            {config?.company_name && t("authorizationSession.atCompany")}.
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mb-4 sm:mb-0"
              onClick={(e) => onSubmit(e, true)}
            >
              {t("authorizationSession.approve")}
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
              onClick={(e) => onSubmit(e, false)}
            >
              {t("authorizationSession.reject")}
            </button>
          </div>
          <div className="text-sm text-gray-500 mt-4">
            {t("authorizationSession.ignoreMessage", { expirationMinutes })}
          </div>
        </div>
      )}

      <PoweredBy />
      <LoadingOverlay isLoading={isLoadingApi} />
    </div>
  );
};

export default AuthorizationSessionWidget;
