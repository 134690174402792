import clsx from "clsx";
import { toPairs } from "lodash";
import { useState } from "react";
import { HiX } from "react-icons/hi";

import { useApi } from "../../contexts/ApiContext";
import useJobApplication from "../../hooks/useJobApplication";
import Button from "../Button/Button";
import ProgressIndicator, { ProgressBadge } from "../ProgressIndicator";

export const getAnswerLabel = (questions, answers, fieldName) => {
  const question = questions.find((q) => q.field_name === fieldName);

  const selectedOption = question?.options?.find(
    (option) => option.value === answers[fieldName],
  );
  return selectedOption ? selectedOption.label : "Not answered";
};

const ScrollingDisclaimer = ({ textItems = [] }) => (
  <div className="relative border-desktopPrimaryLight bg-desktopPrimaryLightest rounded-lg border w-full">
    <div className="py-2 px-4 max-h-36 overflow-scroll text-sm">
      {textItems.map((disclaimer, index) => (
        <p className="my-2" key={index}>
          {disclaimer}
        </p>
      ))}
    </div>
    <div className="absolute bottom-0 left-0 w-full h-6 bg-gradient-to-t from-desktopPrimaryLightest to-transparent pointer-events-none rounded-lg" />
  </div>
);

const StepActions = ({ onBack, onNext, nextLabel, disabledNext }) => (
  <div className="flex justify-end mt-6 space-x-4">
    {onBack && (
      <Button
        onClick={onBack}
        className="px-4 py-2 border rounded focus:outline-none bg-white text-primary border-primary hover:bg-primary/10"
      >
        Back
      </Button>
    )}
    {onNext && (
      <Button
        onClick={onNext}
        className={clsx("px-4 py-2 border rounded focus:outline-none", {
          "bg-primary hover:bg-primary/75 text-white": !disabledNext,
          "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
            disabledNext,
        })}
        disabled={disabledNext}
      >
        {nextLabel || "Next"}
      </Button>
    )}
  </div>
);

const CompletionCard = () => {
  const radius = 24;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="px-3 py-3 flex flex-col gap-4 max-w-full w-full border break-words bg-white text-wrap rounded-lg">
      <div className="flex">
        <ProgressBadge radius={radius} circumference={circumference} />
        <div className="ml-3">
          <h3 className="text-desktopNeutralDark">Questionnaire Completed</h3>
          <p className="text-desktopNeutralVeryDark">
            Your answers have been registered securely.
          </p>
        </div>
      </div>
    </div>
  );
};

const Question = ({ question, answer, setAnswer }) => {
  const onChange = (e) => {
    const value = e.target.value;
    setAnswer(value === "default" ? null : value);
  };

  return (
    <label key={question.field_name} className="block mb-4">
      <span className="text-desktopNeutralDark">{question.label}</span>

      <select
        name={question.field_name}
        id={question.field_name}
        className={
          "mt-2 block w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white focus:outline-none focus:border-primary appearance-none"
        }
        onChange={onChange}
        value={answer || "default"}
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="gray"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>')`, // Custom arrow icon
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 0.5rem center",
          backgroundSize: "1.25rem",
        }}
      >
        <option value="default" disabled="disabled">
          Please select...
        </option>
        {question.options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </label>
  );
};

const ReviewStep = ({ questions, answers }) => (
  <div className="space-y-4">
    <h3 className="text-desktopNeutralDark">Review your answers</h3>
    {questions?.map((q) => (
      <div key={q.field_name} className="flex flex-col">
        <label className="text-desktopNeutralDark">{q.label}</label>
        <span className="text-desktopNeutralVeryDark">
          {getAnswerLabel(questions, answers, q.field_name)}
        </span>
      </div>
    ))}
  </div>
);

const AcknowledgementStep = ({
  date,
  setDate,
  errorApi,
  clearError,
  textItems,
}) => (
  <>
    {errorApi && (
      <div
        className="flex my-4 items-center bg-destopRedLightest border border-desktopRedVeryLight text-red-700 px-4 py-3 rounded relative mt-4 max-w-md"
        role="alert"
      >
        <button
          onClick={clearError}
          className="flex items-center justify-center border border-desktopVeryDark text-desktopVeryDark rounded-full mr-2 p-1"
        >
          <HiX className="h-3 w-3" />
        </button>

        <span className="text-desktopVeryDark block sm:inline">{errorApi}</span>
      </div>
    )}
    <ScrollingDisclaimer textItems={textItems} />
    <label className="block my-4 gap-4">
      <span className="text-desktopNeutralDark">Date</span>
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md text-gray-700"
        required
      />
    </label>
  </>
);

const EEOQuestions = ({ meta }) => {
  const { api } = useApi();

  const [errorApi, setErrorApi] = useState();
  const clearError = () => setErrorApi(null);

  const [loading, setLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [date, setDate] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { acknowledgementText, eeoIntroText, eeoPageAvailable, questions } =
    useJobApplication({ jobPostingId: meta?.posting_id, skip: meta?.complete });

  const setAnswer = (fieldName, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [fieldName]: value,
    }));
  };

  const onBack = () => {
    if (currentStep === "questions" && questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    } else {
      setStepIndex(stepIndex - 1);
    }
  };

  const onNext = () => {
    if (currentStep === "questions" && questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    } else {
      setStepIndex(stepIndex + 1);
    }
  };

  const onSubmit = async () => {
    const application = toPairs(answers).map((pair) => ({
      name: pair[0],
      value: pair[1],
    }));
    const payload = { application, date };
    try {
      setLoading(true);
      await api.postApplication(meta.posting_id, payload);
      setSubmitSuccess(true);
    } catch (error) {
      setErrorApi(
        "There was an error while completing the questionnaire.. Try again or submit it manually.",
      );
    } finally {
      setLoading(false);
    }
  };

  if (meta?.complete || submitSuccess) {
    return <CompletionCard />;
  }

  const allSteps =
    questions.length > 0
      ? ["intro", "questions", "review", "acknowledgement"]
      : ["acknowledgement"];
  const currentStep = allSteps[stepIndex];
  const question = currentStep === "questions" && questions[questionIndex];

  const introStep = currentStep === "intro";
  const questionStep = currentStep === "questions";
  const reviewStep = currentStep === "review";
  const acknowledgementStep = currentStep === "acknowledgement";

  const progressTotalSteps = allSteps.length + questions.length - 1;
  const progressIndex = stepIndex + questionIndex;

  const disabledNext = questionStep
    ? !answers[question.field_name]
    : acknowledgementStep
      ? !date
      : false;

  const title = acknowledgementStep
    ? "Acknowledgement and Consent"
    : "Equal Opportunity Employer/Self identification";

  return (
    <div className="px-3 py-3 flex flex-col gap-4 max-w-full w-full border break-words bg-white text-wrap rounded-lg">
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
            <div className="h-10 w-10 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        )}
        <div>
          {progressTotalSteps > 0 ? (
            <ProgressIndicator
              index={progressIndex}
              totalSteps={progressTotalSteps}
              title={title}
              showLink={!acknowledgementStep && eeoPageAvailable}
            />
          ) : (
            <h3 className="text-desktopNeutralDark text-center">{title}</h3>
          )}

          <hr className="border-t border-gray-300 my-4" />

          {introStep ? (
            <ScrollingDisclaimer textItems={eeoIntroText} />
          ) : questionStep ? (
            <Question
              question={question}
              answer={answers[question.field_name]}
              setAnswer={(value) => setAnswer(question.field_name, value)}
            />
          ) : reviewStep ? (
            <ReviewStep questions={questions} answers={answers} />
          ) : (
            <AcknowledgementStep
              errorApi={errorApi}
              clearError={clearError}
              loading={loading}
              date={date}
              setDate={setDate}
              textItems={acknowledgementText}
            />
          )}
        </div>
        <StepActions
          onBack={stepIndex > 0 && onBack}
          onNext={acknowledgementStep ? onSubmit : onNext}
          nextLabel={acknowledgementStep && "Agree and Submit"}
          disabledNext={disabledNext}
        />
      </div>
    </div>
  );
};

export default EEOQuestions;
