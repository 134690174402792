import { core as log } from "./logger";
import { request } from "./request";
import { clearTerms } from "./utils";

export default class Api {
  static #base = import.meta.env.APPLY_API_URL;

  #token;
  #sid;
  #deploymentContext;

  /**
   * @param {string} token
   * @param {object} options
   * @param {string} options.deploymentContext
   * @param {string} options.sid
   */
  constructor(token, { deploymentContext, sid } = {}) {
    this.#token = token;
    this.#sid = sid;
    this.#deploymentContext = deploymentContext;
  }

  async getConfig(signal) {
    return await this.#get("config", signal);
  }

  async putSession(body, signal) {
    const oldSid = this.#sid;
    const result = await this.#put("session", body, signal);
    this.#sid = result.data.session.sid;

    localStorage.setItem(
      `apply:${this.#token}:session`,
      JSON.stringify({
        sid: result.data.session.sid,
        // last_seen: session.last_seen || null,
      }),
    );

    // if we were issued a new session, we need to clear the TOS acceptance,
    // since the server will not have marked the new session as having accepted the TOS.
    // this will trigger a page refresh to prompt terms acceptance.
    if (oldSid && this.#sid !== oldSid) {
      clearTerms(this.#token);
    }

    return result;
  }

  async getApplicationItems(postingId, signal) {
    return await this.#get(`applications/${postingId}`, signal);
  }

  async postApplication(postingId, body, signal) {
    return await this.#post(`applications/${postingId}`, body, signal);
  }

  async getEeoPageText(signal) {
    const url = new URL(window.location.href);
    const locale = url.searchParams.get("locale");
    return await this.#get(
      locale ? `eeo_page?locale=${locale}` : "eeo_page",
      signal,
    );
  }

  async getMessages(signal) {
    return await this.#get("messages", signal);
  }

  async postMessage(body, signal) {
    return await this.#post("messages", body, signal);
  }

  async acceptTerms(signal) {
    return await this.#post("chats/accept_terms", null, signal).then(() => {
      localStorage.setItem(`apply:${this.#token}:terms`, "accepted");
    });
  }

  async postResume(blob, { uploadToken } = {}) {
    const formData = new FormData();
    if (uploadToken) {
      formData.append("upload_token", uploadToken);
    }

    formData.append("resume", blob);
    return this.#postForm("resumes", formData, null);
  }

  async getAuthorize(authCode, body, signal) {
    return await this.#get(`authorizations/${authCode}`, body, signal);
  }

  async putAuthorize(authCode, body, signal) {
    return await this.#put(`authorizations/${authCode}`, body, signal);
  }

  static fromLocalStorage(token, { deploymentContext } = {}) {
    // Migrate legacy session data
    const legacyData = localStorage.getItem("apply:session");
    if (legacyData) {
      localStorage.removeItem("apply:session");
      localStorage.setItem(`apply:${token}:session`, legacyData);
    }

    const data = localStorage.getItem(`apply:${token}:session`);
    let sid = null;
    if (data !== null) {
      try {
        sid = JSON.parse(data)?.sid;
      } catch (err) {
        log("Failed to parse session data", err);
        localStorage.removeItem("apply:session");
      }
    }

    return new Api(token, { deploymentContext, sid });
  }

  get sid() {
    return this.#sid;
  }

  get token() {
    return this.#token;
  }

  get deploymentContext() {
    return this.#deploymentContext;
  }

  #get(path, signal) {
    return request(new URL(path, Api.#base), {
      method: "GET",
      headers: this.#headers(),
      signal,
    });
  }

  #put(path, body, signal) {
    return request(new URL(path, Api.#base), {
      method: "PUT",
      headers: this.#headers(),
      body,
      signal,
    });
  }

  #post(path, body, signal) {
    return request(new URL(path, Api.#base), {
      method: "POST",
      headers: this.#headers(),
      body,
      signal,
    });
  }

  #postForm(path, formData, signal) {
    return request(new URL(path, Api.#base), {
      method: "POST",
      headers: this.#headers(),
      formData,
      signal,
    });
  }

  #headers() {
    return {
      Authorization: `Bearer ${this.#token}`,
      ...(this.#deploymentContext && {
        "X-Deployment-Context": this.#deploymentContext,
      }),
      ...(this.#sid && { "X-Session-ID": this.#sid }),
    };
  }
}
