import clsx from "clsx";
import { toPairs } from "lodash";
import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import { useParams } from "react-router-dom";

import { getAnswerLabel } from "../../core/components/actions/EEOQuestions";
import Button from "../../core/components/Button/Button";
import PoweredBy from "../../core/components/PoweredBy";
import ProgressIndicator, {
  ProgressBadge,
} from "../../core/components/ProgressIndicator";
import { useApi } from "../../core/contexts/ApiContext";
import { useConfig } from "../../core/contexts/ConfigContext";
import useJobApplication from "../../core/hooks/useJobApplication";

const searchParams = new URLSearchParams(window.location.search);

const REVIEW_STEP = {
  notes: "Review Your Answers",
  id: "review",
};

const ScrollingDisclaimer = ({ textItems = [] }) => (
  <div className="relative border-desktopPrimaryLight bg-desktopPrimaryLightest rounded-lg border w-full text-sm">
    <div className="py-2 px-4 max-h-60 overflow-scroll">
      {textItems.map((disclaimer, index) => (
        <p className="my-2" key={index}>
          {disclaimer}
        </p>
      ))}
    </div>
    <div className="absolute bottom-0 left-0 w-full h-6 bg-gradient-to-t from-desktopPrimaryLightest to-transparent pointer-events-none rounded-lg" />
  </div>
);

const CompletionCard = () => {
  const radius = 24;
  const circumference = 2 * Math.PI * radius;
  return (
    <div className="flex flex-col items-center text-center">
      <ProgressBadge radius={radius} circumference={circumference} size="lg" />
      <div className="mt-3">
        <h3 className="text-desktopNeutralDark">Questionnaire Completed</h3>
        <p className="text-sm mt-4 text-desktopNeutralVeryDark">
          Your answers have been registered securely. You may exit this window
          and return to SMS to finish your application.
        </p>
      </div>
    </div>
  );
};

const InitialStep = ({ setStep, textItems }) => (
  <>
    <div className="flex flex-col items-center text-center max-w-md w-full mb-4">
      <p className="text-desktopNeutralDark text-md">EEO Questions</p>

      {textItems.map((t, i) => (
        <p
          className="text-desktopNeutralVeryDark text-left whitespace-pre-wrap text-sm"
          key={i}
        >
          {t}
        </p>
      ))}
    </div>
    <Button
      onClick={() => setStep("questions")}
      className={
        "w-full max-w-md mt-4 p-4 text-white shadow-md rounded focus:outline-none bg-primary hover:bg-primary/75"
      }
    >
      Begin
    </Button>
  </>
);

const LastStep = ({
  errorApi,
  clearError,
  onComplete,
  date,
  setDate,
  loading,
  textItems,
}) => {
  const disabled = !date;

  const handleAgree = () => {
    onComplete();
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <div className="h-10 w-10 bg-gray-200 rounded-full animate-pulse"></div>
        </div>
      )}
      <div className="flex flex-col items-center text-center max-w-md w-full mb-4">
        <p className="text-desktopNeutralDark text-md">
          Acknowledgement and Consent
        </p>
      </div>
      {errorApi && (
        <div
          className="flex my-4 items-center bg-destopRedLightest border border-desktopRedVeryLight text-red-700 px-4 py-3 rounded relative mt-4 max-w-md"
          role="alert"
        >
          <button
            onClick={clearError}
            className="flex items-center justify-center border border-desktopVeryDark text-desktopVeryDark rounded-full mr-2 p-1"
          >
            <HiX className="h-3 w-3" />
          </button>

          <span className="text-desktopVeryDark block sm:inline">
            {errorApi}
          </span>
        </div>
      )}
      <ScrollingDisclaimer textItems={textItems} />
      <label className="block my-4 gap-4">
        <span className="text-desktopNeutralDark">Date</span>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md text-gray-700"
          required
        />
      </label>
      <Button
        onClick={handleAgree}
        className={clsx(
          "w-full max-w-md my-4 p-4 text-white shadow-md rounded focus:outline-none",
          {
            "bg-primary hover:bg-primary/75": !disabled,
            "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
              disabled,
          },
        )}
        disabled={disabled}
      >
        I Agree
      </Button>
    </div>
  );
};

const Questions = ({
  setStep,
  steps,
  answers,
  setAnswers,
  showWhyAreWeAsking,
}) => {
  const [index, setIndex] = useState(0);
  const [question, setQuestion] = useState(steps[0]);

  useEffect(() => {
    setQuestion(steps[index]);
  }, [index, steps, setQuestion]);

  if (!question) {
    return <></>;
  }

  const advance = () => {
    if (!steps[index + 1]) {
      setStep("agreement");
    } else {
      setIndex(index + 1);
    }
  };

  const goBack = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const totalSteps = steps.length;
  const isLastStep = index === steps.length - 1;
  const disabledNext = !isLastStep ? !answers[question.field_name] : false;

  const onChange = (q) => (e) => {
    const value = e.target.value;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [q.field_name]: value !== "default" ? value : "",
    }));
  };

  const eeoQuestions = steps.slice(0, -1);

  return (
    <div>
      <ProgressIndicator
        index={index}
        totalSteps={totalSteps}
        containerStyle={"flex flex-col mb-4 gap-4"}
        titleStyle={"text-center"}
        size="lg"
        titleContainerStyle={"flex flex-col items-center"}
        title="Equal Opportunity Employer/Self identification"
        showLink={showWhyAreWeAsking}
      />

      {isLastStep ? (
        <div className="space-y-4">
          {eeoQuestions?.map((q) => (
            <div key={q.field_name} className="flex flex-col">
              <label className="text-desktopNeutralDark">{q.label}</label>
              <span className="text-desktopNeutralVeryDark">
                {getAnswerLabel(eeoQuestions, answers, q.field_name)}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <label key={question.field_name} className="block mb-4">
          <span className="text-desktopNeutralDark">{question.label}</span>
          <select
            name={question.field_name}
            id={question.field_name}
            className={
              "mt-2 block w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white focus:outline-none focus:border-primary appearance-none"
            }
            onChange={onChange(question)}
            value={answers[question.field_name] || "default"}
            style={{
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="gray"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>')`, // Custom arrow icon
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 0.5rem center",
              backgroundSize: "1.25rem",
            }}
          >
            <option value="default" disabled="disabled">
              Please select...
            </option>
            {question.options?.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        </label>
      )}

      <div className="flex flex-col mt-4">
        <button
          onClick={advance}
          className={clsx(
            "w-full max-w-md mt-4 p-4 text-white shadow-md rounded focus:outline-none bg-primary hover:bg-primary/75",
            {
              "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
                disabledNext,
            },
          )}
          disabled={disabledNext}
        >
          Next
        </button>
        <button onClick={goBack} disabled={index === 0} className="mt-4">
          <span
            className={clsx({
              "text-primary hover:text-primary/75": index !== 0,
              "text-desktopNeutralMedium cursor-not-allowed": index === 0,
            })}
          >
            Go Back
          </span>
        </button>
      </div>
    </div>
  );
};

const EEOQuestionsWidget = () => {
  const { api } = useApi();

  const { uploadToken } = useParams();
  const pid = searchParams.get("pid");

  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState();
  const clearError = () => setErrorApi(null);

  const [answers, setAnswers] = useState({});
  const [date, setDate] = useState("");
  const [step, setStep] = useState("initial");

  const {
    acknowledgementText,
    eeoIntroText,
    eeoPageAvailable,
    questions: baseQuestions,
  } = useJobApplication({ jobPostingId: pid });

  const questions = baseQuestions.concat(REVIEW_STEP);

  const onComplete = async () => {
    try {
      setLoading(true);
      const application = toPairs(answers).map((pair) => ({
        name: pair[0],
        value: pair[1],
      }));
      await api.postApplication(pid, { uploadToken, application, date });
      setStep("done");
    } catch (error) {
      setErrorApi(
        "There was an error while completing the questionnaire.. Try again or submit it manually.",
      );
    } finally {
      setLoading(false);
    }
  };

  const { config } = useConfig();
  const logoSrc =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  return (
    <div className="flex flex-col w-full h-full bg-white items-center py-8 px-4">
      <div className="flex flex-col items-center text-center max-w-md w-full">
        {logoSrc ? (
          <img
            src={logoSrc}
            alt="Company avatar"
            className={"h-20 object-contain mb-4"}
          />
        ) : (
          <div
            className={"text-desktopNeutralBlack font-semiboald text-3xl mb-4"}
          >
            <span>{config?.company_name}</span>
          </div>
        )}
      </div>
      <div className="items-center h-full w-full max-w-md">
        {step === "initial" ? (
          <InitialStep setStep={setStep} textItems={eeoIntroText} />
        ) : step === "done" ? (
          <CompletionCard />
        ) : step === "agreement" ? (
          <LastStep
            errorApi={errorApi}
            clearError={clearError}
            onComplete={onComplete}
            date={date}
            setDate={setDate}
            loading={loading}
            textItems={acknowledgementText}
          />
        ) : (
          <Questions
            steps={questions}
            setStep={setStep}
            answers={answers}
            setAnswers={setAnswers}
            showWhyAreWeAsking={eeoPageAvailable}
          />
        )}
      </div>
      <div className="w-full mt-auto">
        <hr className="border-t border-gray-300 my-4" />
      </div>
      <PoweredBy />
    </div>
  );
};

export default EEOQuestionsWidget;
