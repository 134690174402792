import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";

import { useApi } from "../../contexts/ApiContext";
import { UploadBox } from "../UploadBox/UploadBox";

const maxFileSizeLimit = 5 * Math.pow(1024, 2);

const ResumeUpload = ({ meta }) => {
  const { api } = useApi();
  const { t } = useTranslation();
  const [resumeAccepted, setResumeAccepted] = useState(meta.complete || false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const handleResume = (file) => {
    if (!file) {
      return;
    }
    setResumeLoading(true);

    api
      .postResume(file)
      .then(() => {
        setUploadError(null);
        setResumeAccepted(true);
        setResumeLoading(false);
        meta.filename = file.name;
      })
      .catch((error) => {
        setUploadError(error);
        setResumeLoading(false);
      });
  };

  return (
    <div className="flex w-full bg-white max-w-full break-words text-wrap rounded-lg my-4">
      {!resumeAccepted ? (
        <UploadBox
          onSubmit={handleResume}
          title={t("components.resumeUpload.uploadBoxTile")}
          fileSizeLimit={maxFileSizeLimit}
          loading={resumeLoading}
          error={uploadError}
        />
      ) : (
        <div className="flex justify-between gap-4 p-4 items-center w-full rounded-lg border">
          <HiCheckCircle className="text-green-600 w-8 h-8" />
          <div className="flex flex-col grow">
            <div className="text-gray-500">
              {t("components.resumeUpload.title")}
            </div>
            <div className="text-sm">{meta?.filename}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeUpload;
