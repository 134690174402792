import { useEffect, useRef } from "react";
import { Transition, TransitionGroup } from "react-transition-group";

import { useChat } from "../contexts/ChatContext";
import ActionComplete from "./actions/ActionComplete";
import EEOQuestions from "./actions/EEOQuestions";
import ResumeUpload from "./actions/ResumeUpload";
import Message from "./Message";

const ActionContainer = ({ action }) => {
  if (!action) {
    return null;
  }

  switch (action.name) {
    case "resume_upload":
      return <ResumeUpload meta={action} />;
    case "authenticated":
      return <ActionComplete title="Authentication Complete" />;
    case "eeo_questions":
      return <EEOQuestions meta={action} />;
    default:
      return null;
  }
};

export default function Chat() {
  const { messages, pending, retry } = useChat();
  const chatRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat when new messages are added
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <div
      className="flex-1 flex flex-col-reverse gap-1.5 py-2 pl-4 pr-2 overflow-y-scroll scroller w-full max-w-sm mx-auto overscroll-contain"
      ref={chatRef}
    >
      <TransitionGroup component={null}>
        {messages.map((message, index) => {
          const isLast = index === 0;
          const isLastInGroup =
            index === 0 || message.role !== messages[index - 1].role;
          const isError = message.meta_error;
          const url =
            message.meta?.type === "link"
              ? {
                  href: message.meta.href,
                  label: message.meta.label,
                }
              : undefined;
          const action =
            message.meta?.type == "action" ? message.meta : undefined;

          return (
            <Transition
              key={message.uuid}
              nodeRef={message.nodeRef}
              timeout={150}
            >
              {(state) => (
                <div>
                  {message.meta?.visible === false || (
                    <Message
                      uuid={message.uuid}
                      body={message.body}
                      url={url}
                      isError={isError}
                      action={action}
                      incoming={message.role === "bot"}
                      isLast={isLast}
                      isLastInGroup={isLastInGroup}
                      pending={message.role === "user" && isLast && pending}
                      transitionState={state}
                      handleRetry={retry}
                      ref={message.nodeRef}
                    />
                  )}
                  {action && <ActionContainer action={action} />}
                </div>
              )}
            </Transition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}
