import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiX } from "react-icons/hi";

import { useApi } from "../contexts/ApiContext";
import { useChat } from "../contexts/ChatContext";
import { useConfig } from "../contexts/ConfigContext";
import { clearSession } from "../lib/utils";
import { CompanyAvatar } from "./Avatars";
import DevtoolsDialog from "./DevtoolsDialog";

/**
 * @param {object} props
 * @param {boolean} props.active
 */
function TitleLoader({ active }) {
  const { t } = useTranslation();
  return (
    <div
      className="flex flex-row items-center gap-2"
      aria-label={t("components.header.loadingAriaLabel")}
      aria-disabled
    >
      <CompanyAvatar loading />
      <div
        className={clsx(
          "flex flex-row items-center w-32 h-5 gap-2 bg-gray-200 rounded-md",
          active && "animate-pulse",
        )}
      ></div>
    </div>
  );
}

/** @param {string} status */
const getStatusClasses = (status) => {
  switch (status) {
    case "idle":
      return "bg-gray-400 ring-gray-200";
    case "connecting":
    case "initialized":
      return "bg-gray-400 ring-gray-200 animate-pulse";
    case "connected":
      return "bg-emerald-400 ring-emerald-200";
    case "disconnected":
      return "bg-red-400 ring-red-200";
    default:
      return "";
  }
};

/** @param {string} status */
const getStatusLabel = (status) => {
  switch (status) {
    case "connected":
      return "connected";
    case "disconnected":
      return "disconnected";
    default:
      return "connecting";
  }
};

/**
 * @param {object} props
 * @param {HTMLElement} props.container
 * @param {boolean} props.isDrawer
 * @param {() => void} props.handleClearSession
 */
function Title({ container, isDrawer, handleClearSession }) {
  const { config } = useConfig();
  const { status } = useChat();

  const [devtoolsOpen, setDevtoolsOpen] = useState(false);

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <CompanyAvatar config={config} className="w-8 h-8" />
        <div className="flex flex-row items-center gap-2 font-semibold">
          {config.company_name}
        </div>

        <button
          className={clsx(
            "h-3 w-3 rounded-full ring-2 transition-all duration-150",
            getStatusClasses(status),
          )}
          aria-label={`Chat status: ${getStatusLabel(status)}`}
          aria-live="polite"
          onClick={() => setDevtoolsOpen(true)}
        ></button>
      </div>
      <DevtoolsDialog
        isOpen={devtoolsOpen}
        onClose={() => setDevtoolsOpen(false)}
        container={container}
        isDrawer={isDrawer}
        handleClearSession={handleClearSession}
      />
    </>
  );
}

/**
 * @param {object} props
 * @param {() => void} props.onClose
 */
function Close({ onClose }) {
  const { t } = useTranslation();
  const handleClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <button
      className="p-1 rounded-md focus:ring-2 focus:outline-none focus:ring-primary/40"
      onClick={handleClick}
      aria-label={t("components.header.closeChatAriaLabel")}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    >
      <HiX className="w-4 h-4" />
    </button>
  );
}

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {HTMLElement} props.container
 * @param {boolean} props.isDrawer
 * @param {boolean} props.showTitle
 */
export default function Header({
  onClose,
  container,
  isDrawer,
  showTitle = true,
}) {
  const { config } = useConfig();
  const { api } = useApi();

  const handleClearSession = () => {
    clearSession(api?.token);
  };

  return (
    <div
      className={clsx(
        "text-gray-900 text-sm rounded-t-xl flex flex-row items-center bg-white border-b",
        close ? "justify-between" : "justify-start",
        showTitle ? "p-4" : "p-2 flex-shrink-0",
      )}
    >
      {showTitle &&
        (config ? (
          <Title
            companyName={config.company_name}
            container={container}
            isDrawer={isDrawer}
            handleClearSession={handleClearSession}
          />
        ) : (
          <TitleLoader active />
        ))}

      {onClose && (
        <div className="ml-auto">
          <Close onClose={onClose} />
        </div>
      )}
    </div>
  );
}
