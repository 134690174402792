import { HiCheckCircle } from "react-icons/hi";

const ActionComplete = ({ title }) => {
  return (
    <div className="flex w-full bg-white max-w-full break-words text-wrap rounded-lg my-4">
      <div className="flex justify-between gap-4 p-4 items-center w-full rounded-lg border">
        <HiCheckCircle className="text-green-600 w-8 h-8" />
        <div className="flex flex-col grow">
          <div className="text-gray-500">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionComplete;
