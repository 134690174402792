import { useTranslation } from "react-i18next";

import { useApi } from "../contexts/ApiContext";
import { useConfig } from "../contexts/ConfigContext";
import { clearSession } from "../lib/utils";
import FooterIcon from "./FooterIcon";

export default function Footer({ disabled = false, showTerms = true }) {
  const { api } = useApi();
  const { config } = useConfig();
  const { t } = useTranslation();

  const handleClearSession = () => {
    clearSession(api?.token);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center p-2 text-xs text-gray-400 border-t rounded-b-xl">
        {showTerms && (
          <div className="flex flex-row gap-1">
            <a href={config?.terms_of_service_url} className="underline">
              {t("components.footer.terms")}
            </a>
            <a href={config?.privacy_policy_url} className="underline">
              {t("components.footer.privacy")}
            </a>
            <button
              className="underline cursor-pointer"
              onClick={handleClearSession}
            >
              {t("components.footer.restart")}
            </button>
          </div>
        )}
        <div className="flex items-center justify-between flex-shrink-0 gap-5 text-center">
          {disabled ? (
            <div className="flex items-center gap-1">
              <span>{t("components.footer.poweredBy")}</span>
              <FooterIcon className="w-4 h-4" />
            </div>
          ) : (
            <a
              href={"https://grayscaleapp.com"}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-1"
            >
              <span>{t("components.footer.poweredBy")}</span>
              <FooterIcon className="w-4 h-4" />
            </a>
          )}
        </div>
      </div>
    </>
  );
}
