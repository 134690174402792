import { useTranslation } from "react-i18next";

import FooterIcon from "./FooterIcon";

const PoweredBy = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-auto flex items-center justify-between gap-5 text-center">
      <a
        href={"https://grayscaleapp.com"}
        target="_blank"
        rel="noreferrer"
        className="flex items-center gap-1"
      >
        <span className="text-sm text-desktopNeutralMedium">
          {t("components.footer.poweredBy")}
        </span>
        <FooterIcon className="w-4 h-4" />
      </a>
    </div>
  );
};

export default PoweredBy;
