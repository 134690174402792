import { useEffect, useState } from "react";

import PoweredBy from "../../core/components/PoweredBy";
import { useApi } from "../../core/contexts/ApiContext";
import { useConfig } from "../../core/contexts/ConfigContext";

const QuestionTitle = ({ title }) => (
  <h1 className="text-desktopNeutralBlack text-center text-3xl mb-4 whitespace-pre-wrap">
    {title}
  </h1>
);

const QuestionSubTitle = ({ subtitle }) => (
  <h2 className="text-desktopNeutralDark text-center text-2xl mb-4 whitespace-pre-wrap">
    {subtitle}
  </h2>
);

const QuestionDescription = ({ description }) => {
  const renderList = (list) => {
    // Check if the list is an array of arrays (multi-column support)
    if (Array.isArray(list[0])) {
      return (
        <div className="flex flex-row gap-x-4">
          {list.map((sublist, columnIndex) => (
            <ul
              key={columnIndex}
              className="flex-1 list-disc ml-6 text-desktopNeutralBlack mb-2"
            >
              {sublist.map((item, index) => (
                <li key={index} className="break-inside-avoid">
                  {typeof item === "string" ? (
                    item
                  ) : (
                    <>
                      <p className="mb-2">{item.item}</p>
                      {item.sublist && renderList(item.sublist)}
                    </>
                  )}
                </li>
              ))}
            </ul>
          ))}
        </div>
      );
    }

    return (
      <ul className={"list-disc ml-6 text-desktopNeutralBlack mb-2"}>
        {list.map((item, index) => (
          <li key={index}>
            {typeof item === "string" ? (
              item
            ) : (
              <>
                <p className="mb-2">{item.item}</p>
                {item.sublist && renderList(item.sublist)}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  };

  if (Array.isArray(description)) {
    return description.map((section) => (
      <div key={section.id} className="my-4">
        {section.title && (
          <h3 className="text-desktopNeutralBlack text-lg font-semibold mb-2">
            {section.title}
          </h3>
        )}
        <p className="text-desktopNeutralBlack mb-2 whitespace-pre-wrap">
          {section.description}
        </p>
        {section.list && renderList(section.list)}
        {section.footer && (
          <p className="text-desktopNeutralVeryDark text-xs mt-4 whitespace-pre-wrap">
            {section.footer}
          </p>
        )}
      </div>
    ));
  }

  return (
    <p className="my-4 text-desktopNeutralBlack whitespace-pre-wrap">
      {description}
    </p>
  );
};

const EEPLegalWidget = () => {
  const { api } = useApi({ locale: "en" });
  const { config } = useConfig();
  const logoSrc =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  const [contentItems, setContentItems] = useState([]);

  useEffect(() => {
    api.getEeoPageText().then((q) => {
      setContentItems(q.data?.content || []);
    });
  }, [api, setContentItems]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-4">
      <div className="flex flex-col items-center text-center w-full mb-4">
        {logoSrc ? (
          <img
            src={logoSrc}
            alt="Company avatar"
            className={"h-20 object-contain mb-4"}
          />
        ) : (
          <div
            className={"text-desktopNeutralBlack font-semiboald text-3xl mb-4"}
          >
            <span>{config?.company_name}</span>
          </div>
        )}
      </div>
      <div className="max-w-6xl">
        {contentItems.map((question) => (
          <div key={question.id} className="mb-12">
            <QuestionTitle title={question.title} />
            <QuestionSubTitle subtitle={question.subtitle} />
            <QuestionDescription description={question.description} />
          </div>
        ))}
      </div>
      <div className="max-w-6xl w-full mt-auto flex flex-col items-center">
        <hr className="border-t border-gray-300 my-4 w-full" />
        <PoweredBy />
      </div>
    </div>
  );
};

export default EEPLegalWidget;
