import { useEffect, useState } from "react";

import { useApi } from "../contexts/ApiContext";

const ACKNOWLEDGEMENT_TEXT = [
  `I understand that employment by the Company is contingent upon the Company obtaining references \
and verifying my legal work status. I understand that, if offered employment, such offer may be \
conditioned upon satisfactory completion of a physical examination, background checks and drug screen. \
I understand that failure to meet all pre-employment contingencies will result in any conditional \
offer of employment being withdrawn.`,
  `I understand that any false or misleading statements or omissions on the application, attached \
resume or any subsequent background forms will be sufficient cause for rejection of this application or \
immediate termination if hired. I also understand that the use of this application form does not \
indicate that there are any positions available and does not in any way obligate the Company to offer \
me employment, or if I am employed, to continue my employment for any specific period of time. \
Furthermore, I understand that just as I am free to resign at any time, the Company reserves the right \
to terminate my employment at any time, with or without cause, and without prior notice. I understand \
that no representative of the Company has the authority to make any assurances to the contrary.`,
  `All qualified applicants will receive consideration for employment without regard to race, color, \
religion, age, sex, national origin, disability, pregnancy, sexual orientation, gender identity/gender \
expression, citizenship status, military or veteran status, genetic information or any other status or \
condition that is protected by applicable law.`,
];

const EEO_INTRO_TEXT = [
  `Your responses to the EEO questions are voluntary. You may choose "I don't wish to answer" for any \
question. The information you provide will be used solely in aggregate to help us improve our processes. \
Rest assured, your answers will not be linked to your application and will not impact your hiring decision.`,
];

const textToArray = (text) =>
  text
    ?.split("\n")
    ?.map((t) => t.trim())
    ?.filter(Boolean);

const useJobApplication = ({ jobPostingId, skip }) => {
  const { api } = useApi();

  const [jobAppResponse, setJobAppResponse] = useState({});

  useEffect(() => {
    if (!jobPostingId || skip) {
      return;
    }
    api.getApplicationItems(jobPostingId).then((q) => {
      setJobAppResponse(q.data);
    });
  }, [api, jobPostingId, skip]);

  // Backward compatibility. Can remove after apply-api is deployed with the new json payload.
  if (Array.isArray(jobAppResponse)) {
    return {
      acknowledgementText: ACKNOWLEDGEMENT_TEXT,
      eeoIntroText: EEO_INTRO_TEXT,
      eeoPageAvailable: true,
      questions: jobAppResponse,
    };
  }

  return {
    acknowledgementText:
      textToArray(jobAppResponse?.acknowledgement_text) || ACKNOWLEDGEMENT_TEXT,
    eeoIntroText: textToArray(jobAppResponse?.eeo_intro_text) || EEO_INTRO_TEXT,
    eeoPageAvailable: jobAppResponse?.eeo_page_available || false,
    questions: jobAppResponse?.questions || [],
  };
};

export default useJobApplication;
