import clsx from "clsx";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronRight } from "react-icons/hi";

import useIOS from "../../embedded/hooks/useIOS";
import { useChat } from "../contexts/ChatContext";
import { useConfig } from "../contexts/ConfigContext";

const subPlaceholders = (str) => {
  const patterns = [
    /\{[^{}]*\}/, // {placeholder}
    /\{\{[^{}]*\}\}/, // {{placeholder}}
    /<[^<>]*>[^<>]*<\/[^<>]*>/, // <tag>placeholder</tag>
    /\[[^[\]]*\]/, // [placeholder]
    /\[\[[^[\]]*\]\]/, // [[placeholder]]
    /\.\.\./, // ...
  ];
  let firstIndex = null;
  const pattern = new RegExp(patterns.map((p) => p.source).join("|"), "g");
  const newStr = str.replace(pattern, (_match, offset) => {
    if (firstIndex === null) firstIndex = offset;
    return "...";
  });
  return {
    text: newStr,
    ...(firstIndex && {
      placeholder: {
        start: firstIndex,
        end: firstIndex + 3,
      },
    }),
  };
};

const Control = forwardRef(function ForwardedControl({ disabled }, inputRef) {
  const { config } = useConfig();
  const { t } = useTranslation();
  const { status, typing, suggestions, sendMessage } = useChat();

  const [inputValue, setInputValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim() !== "") {
      sendMessage(inputValue.trim());
      setInputValue("");
    }
    inputRef.current.focus();
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const iOS = useIOS();

  return (
    <div className="relative w-full max-w-sm p-4 mx-auto">
      <div className="absolute h-2 pointer-events-none left-4 right-3 -top-2 bg-gradient-to-b from-transparent to-gray-50"></div>

      {/* Show suggestions (when not typing) */}
      {!typing && suggestions && suggestions.length > 0 && (
        <div className="flex flex-row flex-wrap gap-1 mb-1 -mt-2">
          {suggestions.map((suggestion) => {
            const { text, placeholder } = subPlaceholders(suggestion);

            const handleClick = () => {
              if (placeholder) {
                setInputValue(text);
                setTimeout(() => {
                  inputRef.current.focus();
                  inputRef.current.setSelectionRange(
                    placeholder.start,
                    placeholder.end,
                  );
                }, 0);
              } else {
                sendMessage(text);
              }
            };

            return (
              <button
                key={suggestion}
                onClick={handleClick}
                disabled={
                  !["connected", "disconnected"].includes(status) || disabled
                }
                className="px-2 py-1 text-xs text-left transition-colors duration-150 border disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-500 text-primary/90 hover:text-primary rounded-xl bg-primary/5 hover:bg-primary/10 border-primary focus:outline-none focus:ring-2 focus:ring-primary/40"
              >
                {text}
              </button>
            );
          })}
        </div>
      )}

      {/* Show typing indicator */}
      <p className="h-3 mb-2 -mt-4 text-xs text-gray-400">
        {typing &&
          t("components.control.typingIndicator", {
            actor:
              config?.assistant_name ||
              config?.company_name ||
              t("components.control.defaultActor"),
          })}
      </p>
      <form
        onSubmit={handleSubmit}
        aria-label={t("components.control.formAriaLabel")}
      >
        <input
          aria-label={t("components.control.messageAriaLabel")}
          className={clsx(
            "block w-full p-4 pr-16 text-gray-900 transition-colors duration-100 bg-white border border-gray-300 shadow-md rounded-xl focus:outline-none focus:ring-2 ring-primary/40",
            iOS ? "text-base" : "text-sm", // iOS performs auto zoom when font size is < 16px (breaking the UI)
          )}
          type="text"
          placeholder={t("components.control.messagePlaceholder")}
          value={inputValue}
          onChange={handleChange}
          disabled={disabled}
          ref={inputRef}
        />
        <button
          type="submit"
          className={clsx(
            "absolute transition-colors disabled:bg-gray-400 h-[36px] px-3 text-white bg-primary/90 rounded-lg end-6 hover:bg-primary focus:ring-2 focus:outline-none focus:ring-primary/40",
            iOS ? "bottom-[28px]" : "bottom-[25px]", // account for iOS input font size
          )}
          disabled={!["connected", "disconnected"].includes(status) || disabled}
          aria-label={t("components.control.buttonAriaLabel")}
        >
          <HiChevronRight className="w-5 h-5" />
        </button>
      </form>
    </div>
  );
});

export default Control;
